<template>
	<div>
		<div class="header">
			<Header></Header>
		</div>
		<div class="main">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import Header from './Header.vue'
export default {
  data () {
    return {

    }
  },
  components: {
    Header
  }
}
</script>

<style scoped>
	.header{
		height: 60px;
		box-shadow: 0px -2px 22px 0px rgba(128, 128, 128, 0.5);
	}
	.main{
		/* height: calc(100vh - 74px); */
	}
</style>
