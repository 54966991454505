<template>
  <div>
    <div class="header-left" @click="backHome()">
      <img src="../../assets/officialwebsite/logo.png" alt="" />
    </div>
    <div class="header-container">
      <div class="header-right">
        <ul class="router-containar">
          <router-link tag="li" to="index" active-class="active"
            >{{ $t("header.home") }}
          </router-link>
          <router-link tag="li" to="product" active-class="active">{{
            $t("header.products")
          }}</router-link>
          <router-link
            tag="li"
            to="community"
            active-class="active"
            @click.native="updateTime"
          >
            {{ $t("header.community") }}
            <div v-if="!isEnterBBS" class="tishi"></div>
          </router-link>
          <router-link tag="li" to="download" active-class="active">{{
            $t("header.download")
          }}</router-link>
          <router-link tag="li" to="business" active-class="active">{{
            $t("header.business")
          }}</router-link>
        </ul>
        <div @click="getdigitalize" style="cursor: pointer;">
          {{ $t("header.digitalize") }}
        </div>
        <div v-if="isLogin" class="user-info">
          <span class="firstChartStyle">{{ firstChart }}</span>
          <div class="welcome">
            <p>
              <span>{{ nickName }}</span>
            </p>
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="personMessage">{{
                $t("header.personalInformation")
              }}</el-dropdown-item>
              <el-dropdown-item @click.native="logout">{{
                $t("header.esc")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <ul v-else class="login-container">
          <span @click="login">{{ $t("common.login") }}</span>
          <span @click="register">{{ $t("header.register") }}</span>
        </ul>
        <div style="width: 14px;"></div>
        <el-dropdown trigger="click" v-if="false">
          <div class="el-dropdown-link">文/A：{{ languageText }}</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="language('zh', '中文')"
              >中文</el-dropdown-item
            >
            <el-dropdown-item @click.native="language('en', 'English')"
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-dialog
      title="空间选择"
      :visible.sync="dialogFormVisible"
      width="800px"
      @close="dialogFormVisible = false"
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item title="个人空间" name="1">
          <div class="company-space">
            <div
              v-for="item in personalSpace"
              :key="item.id"
              class="item-container"
            >
              <p
                class="item-title"
              >
                <!-- 该元素是为了占位使得flex的布局完成居中 -->

                <span class="item-name-box" :title="item.name">{{
                  item.name
                }}</span>
              </p>
              <div class="item-img" @click="intoProject(item)">
                <el-image
                  :src="dataList[1]"
                  alt
                  fit="contain"
                  style="width:190px;height:100px"
                />
              </div>
            </div>
          </div>
        
        </el-collapse-item>
        <el-collapse-item title="已加入的公司" name="2">
			<div class="company-space">
            <div
              v-for="item in companySpace"
              :key="item.id"
              class="item-container"
            >
              <p
                class="item-title"
              >
                <!-- 该元素是为了占位使得flex的布局完成居中 -->

                <span class="item-name-box" :title="item.name">{{
                  item.name
                }}</span>
              </p>
              <div class="item-img" @click="intoProject(item)">
                <el-image
                  :src="dataList[0]"
                  alt
                  fit="contain"
                  style="width:190px;height:100px"
                />
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import i18n from ".././../language";
export default {
  data() {
    return {
      dataList:[require('.././../assets/default/space.png'),require('.././../assets/default/p_space.png')],
      companySpace: [],
      personalSpace: [],
      dialogFormVisible: false,
      activeNames: ["1"],
      isLogin: false,
      isEnterBBS: true, // 是否查看过论坛最新消息
      nickName: "",
      firstChart: "",
      languageText: "中文",
    };
  },
  created() {
    const token = localStorage.getItem("eleToken");
    if (token) {
      this.isLogin = true;
      this.init();
    
    }
    const userInfo = localStorage.getItem("USER_INFO");
    if (userInfo) {
      this.nickName = JSON.parse(localStorage.getItem("USER_INFO")).nickName;
      this.firstChart = this.nickName.slice(0, 1);
    }
    let language = sessionStorage.getItem("theLanguage")
      ? sessionStorage.getItem("theLanguage")
      : "zh";
    this.language(language);
  },
  methods: {
    initData() {
      this.companySpace = []
      this.personalSpace = []
      this.$axios
        .get("/api/Space/GetSpaceAndProjectTypeAndSystemPermission")
        .then((res) => {
          // 判断一下是否是超级管理员
          this.spaceList = res.spaceDtos;
          for (let item of this.spaceList) {
            if (item.type == 1) {
              this.companySpace.push(item);
            } else {
              this.personalSpace.push(item);
            }
          }
        });
    },
    getdigitalize() {
      const eleToken = localStorage.getItem("eleToken");
    
      let url = "";
      if (eleToken) {
        this.initData()
       this.dialogFormVisible=true
      } else {
        this.$message({
          type: "warning",
          message: "请先登录",
        });
      }
      console.log(url);
    },
    intoProject(item) {
   let userinfo= JSON.parse(localStorage.getItem("USER_INFO")) 
   const token = localStorage.getItem("ChengtToken");
   let userDat = {
    nickName:userinfo.nickName,
    userName:userinfo.userName,
    id:userinfo.userId,
    phoneNumber:userinfo.phoneNumbe,
    emDataSpanceID:item.id,
    token:token,
    avatar:'',
   }
   let srt = encodeURIComponent(JSON.stringify(userDat)) 
   let url = `${this.$digitalize}?token=${srt}`
   window.open(url,'blank')
   this.dialogFormVisible=false
	},
    language(text, languageText = "") {
      let language = sessionStorage.getItem("theLanguage");
      sessionStorage.setItem("theLanguage", text);
      if (text == "zh") {
        this.languageText = "中文";
      } else {
        this.languageText = "English";
      }
      if (text == language) {
        return 0;
      }
      if (languageText) {
        i18n.locale = text;
      }
    },
    refresh() {
      location.reload();
    },
    // 获取用户进入论坛时间
    init() {
      // this.getTime()
      // 论坛最新一条的发布时间
      var bbsLastTime = "2020-01-01 10:10:10";
      this.$axios.get("/api/user/GetUserBBStime").then((res) => {
        if (res != "noTime") {
          // 获取论坛的时间
          this.$axios.get("/bbs/question/manage/latestTime").then((res1) => {
            var result = JSON.parse(res1);
            // 论坛发布最新一条消息的时间
            bbsLastTime = result.data;

            var date1 = Date.parse(new Date(res));
            var date2 = Date.parse(new Date(bbsLastTime));
            console.log(date1, date2);
            if (date1 < date2) {
              this.isEnterBBS = false;
            }
          });
        }
      });
    },
    updateTime() {
      const token = localStorage.getItem("eleToken");
      if (token) {
        this.$axios.get("/api/user/UpdataUserBBStime").then((res) => {
          this.isEnterBBS = true;
        });
      }
    },

    personMessage() {
      this.$router.push("/userinfo");
    },
    logout() {
      var userName = localStorage.getItem("userName");
      var password = localStorage.getItem("passWord");
      this.isEnterBBS = true;
      localStorage.clear();
      // this.$router.push("/login");
      this.isLogin = false;
      localStorage.setItem("userName", userName);
      localStorage.setItem("passWord", password);
    },
    backHome() {
      this.$router.push("/index");
    },
    login() {
      this.$router.push({
        name: "login",
        query: {
          type: "index",
        },
      });
    },
    register() {
      this.$router.push({
        name: "login",
        query: {
          type: "register",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company-space{
  height: 300px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
.item-container {
  position: relative;
  margin: 10px 20px 10px 0;
  width: 190px;
  height: 145px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
  border-radius: 2px;
  .item-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    background: #f7f7f8;
    box-sizing: border-box;
    padding: 0 20px;
    border-top: 4px solid #414141;
    font-weight: 500;
    color: #444444;

    .item-name-box {
      // background-color: #000000;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .item-img {
    // position: relative;
    // height: 100px;
    cursor: pointer;

    // .img {
    // 	position: absolute;
    // 	left: 50%;
    // 	top: 50%;
    // 	transform: translate(-50%, -50%);
    // }
  }
}
}

.header-left {
  width: 130px;
  height: 60px;
  float: left;
  margin-left: 2%;
  background-color: #fff;
  cursor: pointer;
  img {
    width: 130px;
    height: 60px;
  }
}
.header-container {
  display: flex;
  // justify-content: space-between;
  justify-content: right;
  align-items: center;
  padding-right: 56px;
  height: 60px;
  background: #ffffff;
  // box-shadow: 0px -2px 22px 0px rgba(128, 128, 128, 0.5);
  .active {
    font-weight: 700;
  }
  .header-right {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 540;
    color: #343434;
    .router-containar {
      display: flex;
      list-style: none;
      li {
        margin: 0 20px;
        cursor: pointer;
      }
      .tishi {
        position: relative;
        float: right;
        background: red;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        top: -3px;
      }
    }
    .user-info {
      margin-left: 50px;
      display: flex;
      align-items: center;
    }
    .firstChartStyle {
      display: inline-block;
      background-color: #475065;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 500;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .welcome {
      display: inline-block;
      width: auto;
      vertical-align: middle;
      padding: 0 5px;
    }
    .login-container {
      margin-left: 50px;
      span {
        display: inline-block;
        width: 73px;
        height: 36px;
        line-height: 36px;
        background: #475065;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        margin-right: 8px;
      }
      span:nth-child(2) {
        background-color: #fff;
        color: #343434;
      }
    }
  }
}
</style>
